.now-playing {
  display: grid;
  --np-min-width: 200px;

  @include breakpoint($mq-not-xx-small) {
    --np-min-width: 50%;
  }

  grid-template-columns: repeat(auto-fill, minmax(var(--np-min-width), 1fr));

  > a {
    color: $c-font;
    padding: 0.3em;
  }

  .indicator {
    color: $c-accent;
    margin-top: -3px;
    display: block;
  }

  cg-board {
    box-shadow: none;
  }
}
