.hooks__chart {
  @extend %abs-100;

  bottom: 0;
  #{$end-direction}: 0;
  overflow: hidden;

  .label {
    color: $c-font-dim;
    font-size: 0.7em;
    position: absolute;
    #{$start-direction}: 3px;
    bottom: 1px;
    text-shadow: $text-shadow;
    font-weight: bold;
  }

  .grid {
    position: absolute;
    #{$start-direction}: 0;
    bottom: 0;

    &.horiz {
      width: 100%;
      border-top: 1px dashed $c-border;
    }

    &.vert {
      height: 100%;
      border-#{$end-direction}: 1px dashed $c-border;
    }
  }

  .canvas {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .plot {
    position: absolute;
    cursor: pointer;
    z-index: 3;
    font-size: 1.6em;
    opacity: 0.7;

    @include transition;

    transform: scale(1);

    &.rated {
      opacity: 0.9;
      color: $c-brag;
    }

    &.cancel {
      opacity: 0.9;
      color: $c-good;
    }

    &.new {
      transform: translateY(-7px);
      opacity: 0;
    }

    &:hover {
      z-index: 2;
      opacity: 1;
      transform: scale(1.15);
    }
  }
}

#hook {
  @extend %box-radius-force, %popup-shadow;

  display: none;
  background: $c-bg-box;
  position: absolute;
  z-index: z('powertip');

  .inner {
    @extend %flex-column;

    text-align: center;

    &-clickable {
      cursor: pointer;
      padding: 5px 0;
    }
  }

  .opponent {
    @extend %metal;

    min-width: 120px;
    padding: 0.5em 0.7em;
  }
}
