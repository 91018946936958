body ::-webkit-scrollbar,
body ::-webkit-scrollbar-corner {
  width: 0.5rem;
  background: $c-bg-box;
}

body ::-webkit-scrollbar-thumb {
  background: $c-shade;
}

body ::-webkit-scrollbar-thumb:hover,
body ::-webkit-scrollbar-thumb:active {
  background: $c-font-dimmer;
}
