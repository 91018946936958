.lobby {
  &__table {
    @extend %flex-column;

    position: relative;
  }

  &__start {
    @extend %flex-column;

    justify-content: stretch;
    flex: 1 1 auto;
    gap: 0.5em;

    button {
      font-size: 1.1em;
      text-align: center;
      padding: 0.5em;

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  &__counters {
    @extend %flex-between, %page-text;
    margin-top: $block-gap / 2;
    display: none;

    a {
      color: $c-font-page;

      @include transition(color);

      &:hover {
        color: $c-link;
      }
    }
  }
}
