.lpools {
  @extend %roboto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 9px;
  padding: 9px;

  @include fluid-size('font-size', 14px, 25px);

  > div {
    @extend %flex-column, %box-radius, %break-word;

    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: $border;

    @if $theme-light {
      background: fade-out($c-bg-box, 0.5);
    } @else {
      background: fade-out($c-font, 0.95);
    }

    color: $c-font;

    @include transition;

    &:hover {
      background: fade-out($c-accent, 0.8);
      opacity: 1;
    }
  }

  .active {
    @extend %popup-shadow;

    background: $c-bg-popup;

    .perf {
      display: none;
    }
  }

  .transp {
    opacity: 0.4;
  }

  .spinner {
    flex: 0 0 auto;
    margin: 0.1em 0 0.6em 0;
    width: 2em;
    height: 2em;
    pointer-events: none;
  }

  .clock {
    display: block;
    font-size: 1.5em;
    line-height: 1.6em;
    letter-spacing: 0.1em;
  }
}
