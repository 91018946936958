@import 'pool';
@import 'hook-chart';
@import 'hook-filters';
@import 'hook-list';

.lobby__app {
  @extend %flex-column;

  @include lobby-app-size;

  user-select: none;

  .lobby-nope & {
    display: none;
  }

  &__content {
    @extend %box-neat;

    flex: 1 1 100%;
    position: relative;

    @if $theme == 'transp' {
      background: fade-out($c-bg-box, 0.2);
    } @else {
      $logo-color: 'dadada';

      @if $theme-dark {
        $logo-color: '383633';
      }

      background: $c-bg-box
        url("data:image/svg+xml,%3Csvg viewBox='-2 -2 54 54' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23#{$logo-color}' stroke='%23#{$logo-color}' stroke-linejoin='round'%0Ad='M38.956.5c-3.53.418-6.452.902-9.286 2.984C5.534 1.786-.692 18.533.68 29.364 3.493 50.214 31.918 55.785 41.329 41.7c-7.444 7.696-19.276 8.752-28.323 3.084C3.959 39.116-.506 27.392 4.683 17.567 9.873 7.742 18.996 4.535 29.03 6.405c2.43-1.418 5.225-3.22 7.655-3.187l-1.694 4.86 12.752 21.37c-.439 5.654-5.459 6.112-5.459 6.112-.574-1.47-1.634-2.942-4.842-6.036-3.207-3.094-17.465-10.177-15.788-16.207-2.001 6.967 10.311 14.152 14.04 17.663 3.73 3.51 5.426 6.04 5.795 6.756 0 0 9.392-2.504 7.838-8.927L37.4 7.171z'/%3E%3C/svg%3E%0A");
    }

    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .lredir {
    background: $c-bg-box;
    display: flex;

    .spinner {
      width: 100px;
      height: 100px;
    }
  }

  .toggle {
    position: absolute;
    padding: 0.6em;
    cursor: pointer;
    z-index: 2;
    font-size: 1.3em;

    &.toggle-filter {
      #{$end-direction}: 0;
    }

    @include transition;

    &:hover {
      color: $c-accent;
    }
  }

  .gamesFiltered {
    color: $c-accent;

    @include transition;

    &:hover {
      color: $c-accent-dim;
    }
  }
}

@include breakpoint($mq-not-xx-small) {
  .lreal_time,
  .lseeks .hooks__list {
    font-size: 0.9em;
  }

  .lobby__app .toggle {
    padding: 0.4em;
  }
}
