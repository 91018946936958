.hook__filters {
  padding: $block-gap 1.5em;
  background: fade-out($c-bg-high, 0.5);
  height: 100%;
  white-space: nowrap;
  display: block;

  td {
    padding: 1em 0;
  }

  td:first-child {
    font-size: 1.1em;
    padding-#{$end-direction}: 2rem;
    width: 25%;
    white-space: normal;
  }

  .checkable {
    padding: 4.3px 2px 4.3px 0;
  }

  .checkable {
    @extend %nowrap-ellipsis;

    label,
    input {
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .regular-checkbox {
    @extend %checkbox;
  }

  tr.variant td:last-child {
    display: flex;
    flex-flow: row wrap;
  }

  tr.variant .checkable {
    width: 33.3%;
  }

  tr.inline .checkable {
    display: inline-block;
    padding: 4.3px 20px 4.3px 2px;
  }

  input {
    margin-#{$end-direction}: 0.3em;
  }

  .range {
    display: block;
    text-align: center;
  }
  .rating-range {
    @extend %flex-center-nowrap;
    justify-content: center;
    input {
      padding: 0.6em 0;
      margin: 0 1ch;
      @include breakpoint($mq-not-xx-small) {
        width: 26vw;
      }
    }
  }

  .actions {
    margin-top: 1.5em;
    text-align: $end-direction;

    button {
      margin-#{$start-direction}: 0.3em;
    }
  }

  @include breakpoint($mq-not-xx-small) {
    padding: $block-gap 1em;

    td {
      max-width: 50vw;
    }

    td:first-child {
      margin-#{$end-direction}: 1rem;
    }

    tr.variant .checkable {
      width: 50%;
    }
  }
}
